class Elementor_Image_Text_Carousel {
    static instance;

    static getInstance() {
        if (!Elementor_Image_Text_Carousel.instance) {
            Elementor_Image_Text_Carousel.instance = new Elementor_Image_Text_Carousel();
        }
        return Elementor_Image_Text_Carousel.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-image_text_carousel.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));

                $carousel.on('changed.owl.carousel', function (event) {
                     Elementor_Image_Text_Carousel.getInstance().set_carousel_width_changed($scope,event);
                })
                $carousel.on('resized.owl.carousel', function (event) {
                    Elementor_Image_Text_Carousel.getInstance().set_carousel_width_changed($scope,event);
                })

            }
        });
    }


    // set_carousel_width($scope) {
    //     let actives = $scope.find('.active:not(.center)'),
    //         $center = $scope.find('.active.center'),
    //         $wrap = $scope.find('.owl-stage-outer'),
    //         $wrap_inner = $scope.find('.owl-stage'),
    //         $item = $scope.find('.owl-item:not(.active)'),
    //         width_wrap = $wrap.outerWidth();
    //     $item.css({
    //         width: (width_wrap / (actives.length)),
    //     });
    //     $center[0].style.width = width_wrap - ((width_wrap / actives.length) * 0.8 * (actives.length - 1)) + 'px';
    //
    //     for (var i = 0; i < actives.length; i++) {
    //         actives[i].style.width = (width_wrap / (actives.length)) * 0.8 + 'px';
    //     }
    //
    //     setTimeout(()=> {
    //         $scope.find('.cloned:not(.active)').css({
    //             width: (width_wrap / (actives.length))
    //         });
    //     },20);
    //
    //     setTimeout(()=> {
    //         $wrap_inner.css({
    //             height: $center.outerHeight()
    //         })
    //     },350)
    //
    // }

    set_carousel_width_changed($scope, event) {
        let actives = $scope.find('.active:not(.center)'),
            $wrap = $scope.find('.owl-stage-outer'),
            $wrap_inner = $scope.find('.owl-stage'),
            width_wrap = $wrap.outerWidth();

        let rtl = event.relatedTarget.settings.rtl ? 1 : -1,
            padding = event.relatedTarget.settings.stagePadding * 2,
            begin = event.relatedTarget.coordinates(event.relatedTarget.current()) + padding,
            end = begin + event.relatedTarget.width() * rtl,
            inner, outer, matches = [], i, n;

        for (i = 0, n = event.relatedTarget._coordinates.length; i < n; i++) {
            inner = event.relatedTarget._coordinates[i - 1] || 0;
            outer = Math.abs(event.relatedTarget._coordinates[i]) + padding * rtl;

            if ((event.relatedTarget.op(inner, '<=', begin) && (event.relatedTarget.op(inner, '>', end)))
                || (event.relatedTarget.op(outer, '<', begin) && event.relatedTarget.op(outer, '>', end))) {
                matches.push(i);
            }
        }

        event.relatedTarget.$stage.children('.active').css({
            width: (width_wrap / (actives.length))
        });

        event.relatedTarget.$stage.children(':eq(' + matches.join('), :eq(') + ')').css({
            width: (width_wrap / (actives.length)) * 0.8
        });

        $(event.target).find(".owl-item").eq(event.item.index).css({
            width: width_wrap - ((width_wrap / actives.length) * 0.8 * (actives.length - 1))
        });

        setTimeout(()=> {
        $wrap_inner.css({
            height: $(event.target).find(".owl-item").eq(event.item.index).outerHeight()
        })
        },350)

    }
}


Elementor_Image_Text_Carousel.getInstance();