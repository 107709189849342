class Elementor_Item_Box {
    static instance;

    static getInstance() {
        if (!Elementor_Item_Box.instance) {
            Elementor_Item_Box.instance = new Elementor_Item_Box();
        }
        return Elementor_Item_Box.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-featured-room.default', ($scope) => {

        });
    }
}


Elementor_Item_Box.getInstance();