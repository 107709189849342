class Elementor_Nearby_Places {
    static instance;

    static getInstance() {
        if (!Elementor_Nearby_Places.instance) {
            Elementor_Nearby_Places.instance = new Elementor_Nearby_Places();
        }
        return Elementor_Nearby_Places.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-nearby-places.default', ($scope) => {
            let $icon = $scope.find('.opal-nearby-places-main-icons'),
                $content = $scope.find('.opal-nearby-places-item');
            $content.not('.first').fadeOut();
            $icon.on('click', function () {
                let $active = $(this).data('tab');
                $content.not($active).fadeOut(300);
                $icon.not($(this)).removeClass('active');
                $(this).addClass('active');
                setTimeout(function () {
                    $scope.find($active).fadeIn(300);
                }, 300);

            });
        });
    }
}

Elementor_Nearby_Places.getInstance();