class Elementor_Views_Derection {
    static instance;

    static getInstance() {
        if (!Elementor_Views_Derection.instance) {
            Elementor_Views_Derection.instance = new Elementor_Views_Derection();
        }
        return Elementor_Views_Derection.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-views-derection.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel'),
                $indicator = $scope.find('.views-derection-indicator');

            if($carousel.length > 0){
                $carousel.owlCarousel({
                    dots: false,
                    items:1,
                    singleItem:true,
                    loop:true,
                    URLhashListener:true,
                });
                $carousel.on('changed.owl.carousel', function(event) {
                    var current = event.item.index;
                    var hash = $(event.target).find(".owl-item").eq(current).find(".item").attr('data-hash');
                    $('.'+hash).addClass('active');
                    $indicator.addClass($('.'+hash).attr('data-derection'));
                });

                $carousel.on('change.owl.carousel', function(event) {
                    var current = event.item.index;
                    var hash = $(event.target).find(".owl-item").eq(current).find(".item").attr('data-hash');
                    $('.'+hash).removeClass('active');
                    $indicator.removeClass($('.'+hash).attr('data-derection'));
                });
            }
        });
    }
}

Elementor_Views_Derection.getInstance();