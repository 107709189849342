class Elementor_Room {
    static instance;

    static getInstance() {
        if (!Elementor_Room.instance) {
            Elementor_Room.instance = new Elementor_Room();
        }
        return Elementor_Room.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-room.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            } else {
                let $container = $scope.find('.isotope-grid');
                 let currentIsotope = $scope.find('.isotope-grid').isotope({
                     masonry: {
                         columnWidth: 1
                     }

                 });
                $scope.find('.elementor-room__filters li').on('click', function () {
                    $(this).parents('ul.elementor-room__filters').find('li.elementor-room__filter').removeClass('elementor-active');
                    $(this).addClass('elementor-active');
                    let selector = $(this).attr('data-filter');
                    currentIsotope.isotope({filter: selector});
                });

                currentIsotope.imagesLoaded(function () {
                    currentIsotope.isotope('layout');
                });
            }
        });
    }
}

Elementor_Room.getInstance();