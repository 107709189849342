class Elementor_Available_Residences {
    static instance;

    static getInstance() {
        if (!Elementor_Available_Residences.instance) {
            Elementor_Available_Residences.instance = new Elementor_Available_Residences();
        }
        return Elementor_Available_Residences.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {

            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-available-residences.default', ($scope) => {

            if ($(window).width() > 767) {
                $scope.find('.scrollbar-inner').scrollbar();
            }
            this.header_title($scope);
            $(window).resize(() => {
                this.header_title($scope);

                if ($(window).width() > 767) {
                    $scope.find('.scrollbar-inner').scrollbar();
                } else {
                    $scope.find('.scrollbar-inner').scrollbar('destroy');
                }
            });
            let $row = $scope.find('.available-residences-row'),
                $collate = $scope.find('.available-residences-row-collate');
            $scope.find('.available-residences-row:first-child').addClass('active');
            $scope.find('.available-residences-row-collate:first-child').addClass('active');
            let data = $scope.find('.available-residences-row:first-child').data('residence');
            Elementor_Available_Residences.getInstance().insertDataID($scope, data);


            $row.on('click', function () {
                data = $(this).data('residence');
                $row.removeClass('active');
                $(this).addClass('active');
                $collate.removeClass('active');
                $scope.find('.available-residences-' + data.id).addClass('active');
                Elementor_Available_Residences.getInstance().insertDataID($scope, data);

            });

            $scope.find('a.contactform-button').magnificPopup({
                type: 'inline',
                removalDelay: 500,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = this.st.el.attr('data-effect');
                    }
                },
                midClick: true
            });

        });
    }

    insertDataID($scope, data) {
        if ($scope.find('.available-residences-button').length) {
            let form = $scope.find('.available-residences-button').attr('href'),
                mess = $scope.find('.available-residences-wrapper').data('residences'),
                input = $(form + ' textarea[name="opal-available-residences"]');
            if (input.length) {
                let input_val = mess.mes.replace('$1%s', data.residence).replace('$2%s', data.id) + String.fromCharCode(13, 10) + 'URL: ' + mess.admin_url + 'post.php?post=' + data.id + '&action=edit' + String.fromCharCode(13, 10) + '-----------------' + String.fromCharCode(13, 10);
                input.val(input_val);
            }

        }
    }

    insertParam2(key, value) {
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        var s = document.location.search;
        var kvp = key + "=" + value;

        var r = new RegExp("(&|\\?)" + key + "=[^\&]*");

        s = s.replace(r, "$1" + kvp);

        if (!RegExp.$1) {
            s += (s.length > 0 ? '&' : '?') + kvp;
        }
        ;

        //again, do what you will here
        window.history.replaceState(null, null, s);
    }

    header_title($scope) {
        let divs = $scope.find('.header-title div'),
            tds = $scope.find('.available-residences-table td');
        for (var i = 0; i < divs.length; i++) {
            if (divs[i].clientWidth != tds[i].clientWidth) {
                var w = tds[i].clientWidth;
                divs[i].style.width = w + 'px';
            }
        }
    }
}

Elementor_Available_Residences.getInstance();