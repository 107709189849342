class Elementor_Chart {
    static instance;

    static getInstance() {
        if (!Elementor_Chart.instance) {
            Elementor_Chart.instance = new Elementor_Chart();
        }
        return Elementor_Chart.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-chart.default', ($scope) => {
            let $canvas = $scope.find('.chart-area');
            let settings = $canvas.data('settings');
            new Chart($canvas.get(0), {
                type: settings.type,
                data: {
                    labels: settings.name,
                    datasets: [{
                        data: settings.number,
                        backgroundColor: settings.color,
                        borderWidth: 0,
                    }]
                },
                options: {
                    //cutoutPercentage: 60,
                    responsive: true,
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        enabled: false,
                    },
                    title: {
                        display: false,
                    },
                },
            });

        });
    }
}

Elementor_Chart.getInstance();

