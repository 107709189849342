class Elementor_Featured_Box{
    static instance;

    static getInstance() {
        if (!Elementor_Featured_Box.instance) {
            Elementor_Featured_Box.instance = new Elementor_Featured_Box();
        }
        return Elementor_Featured_Box.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/featured-box.default', ( $scope ) => {

        } );

    }
}


Elementor_Featured_Box.getInstance();