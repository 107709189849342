class Elementor_Vertical_Scroll{
    static instance;

    static getInstance() {
        if (!Elementor_Vertical_Scroll.instance) {
            Elementor_Vertical_Scroll.instance = new Elementor_Vertical_Scroll();
        }
        return Elementor_Vertical_Scroll.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-vertical-scroll.default', ( $scope ) => {
            // Code Here

        } );
    }
}
Elementor_Vertical_Scroll.getInstance();