class Elementor_Featured_Room {
    static instance;

    static getInstance() {
        if (!Elementor_Featured_Room.instance) {
            Elementor_Featured_Room.instance = new Elementor_Featured_Room();
        }
        return Elementor_Featured_Room.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-featured-room.default', ($scope) => {
            $scope.find('.elementor-featured-room-zoom').magnificPopup({
                type: 'image',
                closeOnContentClick: true,
                mainClass: 'mfp-img-mobile',
                image: {
                    verticalFit: true
                }

            });
        });
    }
}


Elementor_Featured_Room.getInstance();