class Elementor_Image_Map{
    static instance;

    static getInstance() {
        if (!Elementor_Image_Map.instance) {
            Elementor_Image_Map.instance = new Elementor_Image_Map();
        }
        return Elementor_Image_Map.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-image-map.default', ( $scope ) => {
            let $item = $scope.find('.image-map-item');
            if($item.length) {
                $item.imageMapPro($item.data('code'));
            }
        } );
    }
}
Elementor_Image_Map.getInstance();