class PopupTemplate{
    static instance;

    static getInstance() {
        if (!PopupTemplate.instance) {
            PopupTemplate.instance = new PopupTemplate();
        }
        return PopupTemplate.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-popup-template.default', ( $scope ) => {
            let $element_template = $scope.find('.elementor-popup-template-item');
            let $overlay = $scope.find('.elementor-popup-overlay');
            let $button_click = $scope.find('.elementor-toggle i');
            let button_clickdl = $scope.find('.button-close');

            $button_click.click(function () {
                $element_template.addClass('template-active');
                $('body').addClass('overflow-hidden');
            });

            $overlay.click(function () {
                $element_template.removeClass('template-active');
                $('body').removeClass('overflow-hidden');
            });

            button_clickdl.click(function () {
                $element_template.removeClass('template-active');
                $('body').removeClass('overflow-hidden');
            });

        } );
    }
}
PopupTemplate.getInstance();
