class Elementor_Reason_Carousel{
    static instance;

    static getInstance() {
        if (!Elementor_Reason_Carousel.instance) {
            Elementor_Reason_Carousel.instance = new Elementor_Reason_Carousel();
        }
        return Elementor_Reason_Carousel.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-reason_carousel.default', ( $scope ) => {
            let $carousel = $scope.find('.owl-carousel');
            $(window).on("load", function () {
                let $heightimage = $scope.find('.elementor-image-framed figure img').height();
                let $content = $scope.find('.elementor-content-wrap');
                $content.outerHeight($heightimage);
            });
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                data.loop = false;
                data.startPosition = 0;
                data.navigation = 'dots';
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
        } );
    }
}


Elementor_Reason_Carousel.getInstance();