class Elementor_FullPage {
    static instance;

    static getInstance() {
        if (!Elementor_FullPage.instance) {
            Elementor_FullPage.instance = new Elementor_FullPage();
        }
        return Elementor_FullPage.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            let $body = $('body');
            if ($body.hasClass('opal-fullpage') && !elementorFrontend.isEditMode()) {
                let $section = $body.find('.entry-content .elementor-section-wrap > .elementor-section');
                let anchors = [];
                if($section.length > 0) {
                    $section.each((index, element) => {
                        anchors[index] = "Page" + ++index;
                    });
                    $section.wrap("<div class='section'></div>");
                    new fullpage('.entry-content .elementor-section-wrap', {
                        licenseKey        : 'OPEN-SOURCE-GPLV3-LICENSE',
                        anchors: anchors,
                        navigation        : true,
                        navigationPosition: 'right',
                        responsiveWidth   : 768,
                        responsiveHeight  : 600,
                        scrollOverflow    : true,
                        onLeave           : (origin, destination, direction) => {
                            let $container = $(destination.item);
                            this.counter($container);
                            this.progress($container);
                            this.animate($container);
                        }
                    });
                }
            }
        });
    }

    counter($container) {
        let $number = $container.find('.elementor-counter-number');
        if ($number.length > 0) {
            $number.each((index, element) => {
                let data = $(element).data();
                let decimalDigits = data.toValue.toString().match(/\.(.*)/);
                if (decimalDigits) {
                    data.rounding = decimalDigits[1].length;
                }
                $(element).numerator(data);
            });
        }
    }

    progress($container) {
        let $progressbar = $container.find('.elementor-progress-bar');
        if ($progressbar.length > 0) {
            $progressbar.each((index, element) => {
                $(element).css('width', $(element).data('max') + '%');
            });

        }
    }

    animate($container) {
        let $element = $container.find('.elementor-invisible');
        if ($element.length > 0) {
            $element.each((index, element) => {
                let $settings      = $(element).data('settings'),
                    animation      = $settings['_animation'],
                    animationDelay = typeof $settings['_animation_delay'] === 'undefined' ? 700 : $settings['_animation_delay'];
                $(element).removeClass(animation);
                setTimeout(function () {
                    $(element).removeClass('elementor-invisible').addClass(animation);
                }, animationDelay);
            });
        }
    }
}


Elementor_FullPage.getInstance();