class Elementor_Link_Showcase{
    static instance;

    static getInstance() {
        if (!Elementor_Link_Showcase.instance) {
            Elementor_Link_Showcase.instance = new Elementor_Link_Showcase();
        }
        return Elementor_Link_Showcase.instance;
    }
    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/erios-link-showcase.default', ($scope) => {
            let $tabs = $scope.find('.link-showcase-title-wrapper');
            let $contents = $scope.find('.link-showcase-contnet-wrapper');
            // Active tab
            $contents.find('.elementor-active').show();
            var windowsize = $(window).width();

            if (windowsize > 567) {
                $tabs.find('.elementor-link-showcase-title').hover(function (e) {
                    e.preventDefault();
                    $tabs.find('.elementor-link-showcase-title').removeClass('elementor-active');
                    $contents.find('.elementor-link-showcase-content').removeClass('elementor-active').hide();
                    $(this).addClass('elementor-active');
                    let id = $(this).attr('aria-controls');
                    $contents.find('#' + id).addClass('elementor-active').show();
                });
            } else {
                $tabs.find('.elementor-link-showcase-title').hover(function (e) {
                    e.preventDefault();
                    if ($(this).hasClass('elementor-active')) {
                        $(this).removeClass('elementor-active');
                        let id = $(this).attr('aria-controls');
                        $contents.find('#' + id).removeClass('elementor-active').slideUp();
                    } else {
                        $tabs.find('.elementor-link-showcase-title').removeClass('elementor-active');
                        $contents.find('.elementor-link-showcase-content').removeClass('elementor-active').slideUp();
                        $(this).addClass('elementor-active');
                        let id = $(this).attr('aria-controls');
                        $contents.find('#' + id).addClass('elementor-active').slideToggle();
                    }
                });
            }
        });
    }

}
Elementor_Link_Showcase.getInstance();
